import React, { useState } from 'react'
import FormField from '../components/FormField'
import { DatePicker } from '@mui/x-date-pickers';
import axios from 'axios';


function Bookings() {
    const [bookingRequestStatus, setBookingRequestStatus] = useState(null);

    function submitForm(event) {
        const inputs = event.currentTarget.elements

        console.log(axios.defaults)

        axios.post('/api/bookings',
            {
                email: inputs.email.value,
                firstName: inputs.firstName.value,
                lastName: inputs.lastName.value,
                phoneNumber: inputs.phoneNumber.value,
                numGuests: inputs.numGuests.value,
                bookingType: inputs.bookingType.value,
                startDate: inputs.startDate.value,
                endDate: inputs.endDate.value
            },
        )
            .then(function (response) {
                if (response.data.Status === "success") {
                    console.log("SUCCESS")
                    setBookingRequestStatus(true);
                }
            })
            .catch(function (err) {
                event.preventDefault()
                console.log(err)
            })
    }

    return (
        <div className='flex justify-center'>
            < div className='grow max-w-lg py-4 mt-4 mx-2 border border-black bg-slate-300 rounded-2xl' >
                <div
                    className='flex justify-center pb-12'
                >
                    <h1 className='w-48 pb-2 text-lg font-semibold text-gray-700 border-b border-gray-700'>
                        Book your stay now!
                    </h1>
                </div>
                <form className='flex-col items-center space-y-8 sm:space-y-6' onSubmit={submitForm}>
                    <div className='justify-center space-y-8 sm:flex sm:space-x-4 sm:space-y-0'>
                        <FormField label='First Name' inputName='firstName' type='text' required={true} />
                        <FormField label='Last Name' inputName='lastName' type='text' required={true} />
                    </div>
                    <div className='justify-center space-y-8 sm:flex sm:space-x-4 sm:space-y-0'>
                        <FormField label='# of Guests' inputName='numGuests' type='number' defaults='N/A' />
                        <FormField label='Booking Type' inputName='bookingType' type='dropdown' options={['Main House', 'Lower Apt.', 'Entire House']} />
                    </div>
                    <div className='justify-center space-y-2 sm:flex sm:space-x-4 sm:space-y-0'>
                        <div className='w-full sm:w-auto'>
                            <DatePicker name="startDate" label='Start Date' className='bg-white w-48 rounded-md' required={true} />
                        </div>
                        <div className='w-full sm:w-auto'>
                            <DatePicker name="endDate" label='End Date' className='bg-white w-48 rounded-md' required={true} />
                        </div>
                    </div>
                    <div className='justify-center space-y-8 sm:flex sm:space-x-4 sm:space-y-0'>
                        <FormField label='Email' inputName='email' type='text' required={true} />
                        <FormField label='Phone Number' inputName='phoneNumber' type='text' required={true} />
                    </div>
                    <div className='pt-4 pb-2'>
                        <button
                            type='submit'
                            className='font-semibold rounded-xl px-3 shadow-lg ring-2 ring-offset-2 ring-offset-blue-200 bg-gradient-to-b from-blue-400 via-blue-300 to-blue-200 opacity-85 '
                        >
                            Submit
                        </button>
                    </div>
                    {bookingRequestStatus === true && <span>Booking Request Submitted</span>}
                </form>
            </div >
        </div >
    )
}

export default Bookings;