import React from 'react';
import { useNavigate } from 'react-router-dom';
import ImageGallery from 'react-image-gallery';
import './Home.css';
import HomeText from '../static/text/home';


function SectionHeader(
    props
) {

    return (
        <>
            <div className='relative h-20 w-2/3 flex justify-start items-end bg-gradient-to-bl from-slate-200 via-slate-200  to-white'>
                <h1 className='text-4xl font-cursive pl-8'>{props.title}</h1>
            </div>
            <div className='w-2/3 bg-gradient-to-r from-red-700 via-red-600 to-slate-200 h-1 mb-4'></div>
        </ >
    )
}

function Home() {
    const navigate = useNavigate();

    const linkProps = "text-sm text-white w-1/3 h-full flex items-center justify-center border-x border-slate-600 hover:bg-slate-600 sm:text-lg"

    return (
        <div>
            <button
                id='bookNowButton'
                className='fixed z-10 px-4 py-2 font-semibold border border-blue-300 rounded-3xl bg-gradient-to-r from-blue-400 via-blue-300 to-blue-200 hover:from-blue-300 hover:via-blue-200 hover:to-blue-100 bottom-6 right-1/4 left-1/4 inset-x-0 sm:left-auto sm:bottom-12 sm:right-12'
                onClick={() => navigate('/bookings')}
            >
                Book Now!
            </button>

            <div id='welcomeGraphic' className="flex justify-center">
                <div className='relative'>
                    <img
                        src='/assets/images/house/exterior/Front Exterior 2.JPG'
                        alt='Front exterior of house'
                        className="opacity-55"
                    />
                    <div className='absolute bottom-0 flex justify-center'>
                        <div className='flex justify-center items-center min-h-min bg-slate-800 bg-opacity-65 rounded-t-2xl lg:min-h-44'>
                            <p
                                className={`p-2 font-serif text-white text-xs tracking-wide md:text-base lg:text-xl xl:text-2xl`}
                            >
                                {HomeText.welcome}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div id='sectionNavBar' className='flex w-full h-12 justify-center bg-slate-800 rounded-b-lg sm:h-10'>
                <div className='flex grow justify-center items-center font-semibold sm:w-2/3'>
                    <a href='#locationInfo' className={linkProps}>Location</a>
                    <a href='#mainHouseTour' className={linkProps}>Main House</a>
                    <a href='#lowerAptTour' className={linkProps}>Lower Apt.</a>
                    <a href='#sharedSpacesTour' className={linkProps}>Shared Spaces</a>
                </div>

            </div>

            <section id='locationInfo'>

                <SectionHeader title="Location" />

                <div id='mainSectionContentDiv' className='px-2 pt-2 lg:flex lg:justify-center lg:items-center lg:w-full'>
                    <div id='locationInfoText' className='px-6 pb-4 lg:pb-0 lg:pr-8'>
                        {HomeText.location.map((locationObj, key) => {
                            return (
                                <div key={key}>
                                    <div className='text-left'>{locationObj.text}</div>
                                    <ul className='py-4 pl-8'>
                                        {locationObj.items.map((value, key) => {
                                            return <li key={key} className='text-left'>{`- ${value}`}</li>
                                        })}
                                    </ul>
                                </ div>
                            )
                        })}
                    </div>

                    <div id='googleMapsEmbedding' className='flex justify-center w-full'>
                        <iframe
                            title='Google maps embedding'
                            className='w-full max-w-lg aspect-square'
                            loading="lazy"
                            src={`https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ7S8KlNLLTIgRxWtoXhAztqw&key=${process.env.REACT_APP_GOOGLE_MAPS_SK}`}
                        >
                        </iframe>
                    </div>
                </div>

            </section >

            < section id='mainHouseTour' >
                <SectionHeader title="Main House" />

                <p className='my-4 px-8'>{HomeText.mainHouseTour}</p>

                <div className='px-2'>
                    <ImageGallery items={
                        [
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Living Room 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Living Room 1.JPG",
                                description: "Living Room",
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Living Room 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Living Room 2.JPG",
                                description: "Living Room"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Kitchen 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Kitchen 1.JPG",
                                description: "Kitchen"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Kitchen 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Kitchen 2.JPG",
                                description: "Kitchen"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main Bath.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main Bath.JPG",
                                description: "Bath",
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Dining Room.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Dining Room.JPG",
                                description: "Dining Area"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Dining into Office area.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Dining into Office area.JPG",
                                description: "Dining Area"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House Office.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House Office.JPG",
                                description: "Office"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/master/Master Bedroom 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/master/Master Bedroom 1.JPG",
                                description: "Master Bedroom (King)"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/master/Master Bedroom 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/master/Master Bedroom 2.JPG",
                                description: "Master Bedroom (King)"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/master/Master Bath 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/master/Master Bath 1.JPG",
                                description: "Master Bath"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/master/Master Bath 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/master/Master Bath 2.JPG",
                                description: "Master Bath"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/second_bedroom/Main House 2nd Bedroom & desk.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/second_bedroom/Main House 2nd Bedroom & desk.JPG",
                                description: "2nd Bedroom (Queen)"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/second_bedroom/Main House 2nd Bedroom & desk 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/second_bedroom/Main House 2nd Bedroom & desk 2.JPG",
                                description: "2nd Bedroom (Queen)"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 1.JPG",
                                description: "3rd Bedroom (Queen) - Second Floor"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 2.JPG",
                                description: "3rd Bedroom (Queen) - Second Floor"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 3.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/third_bedroom/Main House 3rd bedroom 3.JPG",
                                description: "3rd Bedroom (Queen) - Second Floor"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/fourth_bedroom/Main House 4th Bedroom 1.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/fourth_bedroom/Main House 4th Bedroom 1.JPG",
                                description: "4th Bedroom (2 twin + trundle) - Second Floor"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/fourth_bedroom/Main House 4th Bedroom 2.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/fourth_bedroom/Main House 4th Bedroom 2.JPG",
                                description: "4th Bedroom (2 twin + trundle) - Second Floor"
                            },
                            {
                                original: "/assets/images/house/interior/upper_apt/Main House 3rd Floor powder room.JPG",
                                thumbnail: "/assets/images/house/interior/upper_apt/Main House 3rd Floor powder room.JPG",
                                description: "Second Floor Bathroom"
                            },
                        ]
                    } />
                </div>
            </section >

            < section id='lowerAptTour' >
                <SectionHeader title="Lower Apartment" />

                <p className='my-4 px-8'>{HomeText.lowerAptTour}</p>

                <div className='px-2'>
                    <ImageGallery items={
                        [
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Living Room.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Living Room.JPG",
                                description: "Living Room",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Living Room 2.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Living Room 2.JPG",
                                description: "Living Room",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Mini Kitchen.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Mini Kitchen.JPG",
                                description: "Mini Kitchen",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Mini Kitchen 2.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Mini Kitchen 2.JPG",
                                description: "Mini Kitchen",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Bath 1.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Bath 1.JPG",
                                description: "Main Bathroom",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/Lower Apartment Bath 2.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/Lower Apartment Bath 2.JPG",
                                description: "Main Bathroom",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/master/Lower Apartment Main Bedroom 1.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/master/Lower Apartment Main Bedroom 1.JPG",
                                description: "Master Bedroom (Queen)",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 1.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 1.JPG",
                                description: "Second Bedroom (Queen)",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 2.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 2.JPG",
                                description: "Second Bedroom (Queen)",
                            },
                            {
                                original: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 3.JPG",
                                thumbnail: "/assets/images/house/interior/lower_apt/second_bedroom/Lower Apartment 2nd Bedroom 3.JPG",
                                description: "Second Bedroom (Queen)",
                            },

                        ]
                    } />
                </div>

            </section >

            < section id='sharedSpacesTour' >
                <SectionHeader title="Shared Spaces" />

                <p className='my-4 px-8'>{HomeText.sharedSpacesTour}</p>

                <div className='px-2'>
                    <ImageGallery items={
                        [
                            {
                                original: "/assets/images/house/exterior/Front Exterior 2.JPG",
                                thumbnail: "/assets/images/house/exterior/Front Exterior 2.JPG",
                                description: "Front Entrance",
                            },
                            {
                                original: "/assets/images/house/exterior/Exterior Side Entrance.JPG",
                                thumbnail: "/assets/images/house/exterior/Exterior Side Entrance.JPG",
                                description: "Side Entrance + Patio (Day)",
                            },
                            {
                                original: "/assets/images/house/exterior/Side Entrance 2.JPG",
                                thumbnail: "/assets/images/house/exterior/Side Entrance 2.JPG",
                                description: "Side Entrance + Patio (Night)",
                            },
                            {
                                original: "/assets/images/house/exterior/Exterior yard 1.JPG",
                                thumbnail: "/assets/images/house/exterior/Exterior yard 1.JPG",
                                description: "Private Backyard",
                            },
                            {
                                original: "/assets/images/house/exterior/Exterior yard 2.JPG",
                                thumbnail: "/assets/images/house/exterior/Exterior yard 2.JPG",
                                description: "Private Backyard",
                            },
                            {
                                original: "/assets/images/house/exterior/Exterior yard 3.JPG",
                                thumbnail: "/assets/images/house/exterior/Exterior yard 3.JPG",
                                description: "Private Backyard",
                            },
                        ]
                    } />
                </div>

            </section >

        </div >
    )
}

export default Home