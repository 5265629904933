import { Route, Routes, NavLink, useNavigate } from 'react-router-dom';
import Home from './pages/Home.js';
import Bookings from './pages/Bookings.js';
import './App.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'


function App() {
  const navigate = useNavigate();

  function GetLinkProps(props) {
    return `flex w-1/3 h-full justify-center items-center font-oswald text-white text-sm sm:text-xl hover:bg-slate-600 ${props.isActive ? "underline bg-teal-500" : ""}`
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className='App min-h-screen flex flex-col'>

        <div id="header" className='flex justify-between bg-slate-800 h-16 sm:h-20'>
          <button id="appTitleLogo" className='flex-col h-auto ml-4 mr-2 sm:ml-20' onClick={() => navigate('/')}>
            <div>
              <h1 className='flex min-w-28 justify-center text-white font-cursive text-2xl sm:text-4xl'>
                Purple Rain
              </h1>
              <h2 className='flex justify-center text-white font-serif text-xs sm:text-sm'>
                Lexington, VA
              </h2>
            </div>
          </button>

          <div id="navBar" className='flex grow max-w-md justify-end items-center'>
            <NavLink to='/' className={GetLinkProps}>Home</NavLink>
            <NavLink to='/bookings' className={GetLinkProps}>Book Now</NavLink>
          </div>
        </div>

        <div class='relative flex flex-col flex-grow'>
          <main className='bg-slate-200 flex-1'>
            <Routes>
              <Route path="/" exact Component={Home} />
              <Route path="/bookings" exact Component={Bookings} />
            </Routes>
          </main>
        </div>
      </div >
    </ LocalizationProvider >
  );
}

export default App;
