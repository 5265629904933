import React from 'react'

function FormField(props) {
    if (props.type === 'text') {
        return (
            <div className='relative'>
                <label className='absolute -top-4 text-xs pl-1 text-gray-600 font-semibold' htmlFor={props.inputName}>
                    {`${props.label}:`}
                    {props.required && <span className='text-red-500'> * </span>}
                </label>
                <input
                    id={props.inputName}
                    value={props.initial}
                    name={props.inputName}
                    className='p-1 px-2 text-gray-600 bg-gray-50 border border-gray-400 rounded-md'
                    type='text'
                    required={props.required}
                />
            </div >
        )
    } else if (props.type === 'dropdown') {
        return (
            <div className='relative'>
                <label className='absolute -top-4 text-xs pl-1 text-gray-600 font-semibold' htmlFor={props.inputName}>
                    {`${props.label}:`}
                    {props.required && <span className='text-red-500'> * </span>}
                </label>
                <select
                    id={props.inputName}
                    name={props.inputName}
                    className='w-48 p-1 px-2 text-gray-600 bg-gray-50 border border-gray-400 rounded-md'
                    required={props.required}
                >
                    {props.options.map((option) =>
                        <option key={option}>{option}</option>
                    )}
                </select>
            </div >
        )
    } else {
        return (
            <div className='relative'>
                <label className='absolute -top-4 text-xs pl-1 text-gray-600 font-semibold' htmlFor={props.inputName}>
                    {`${props.label}:`}
                    {props.required && <span className='text-red-500'> * </span>}
                </label>
                <input
                    id={props.inputName}
                    value={props.initial}
                    name={props.inputName}
                    className='p-1 px-2 text-gray-600 bg-gray-50 border border-gray-400 rounded-md'
                    type={props.type}
                    required={props.required}
                />
            </div >
        )
    }
}

export default FormField